import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export default function OurServices(props) {
  const {
    ourservices,
    column_count,
  } = props;

  return (
    <React.Fragment>
      <div className="our-services">
        <div className="trustsection__bgimg">
            <PreviewCompatibleImage imageInfo={ourservices.bgimage}/>
          </div>
        <section className="section">
          <div className="container">
            <div className="section">
              <div className="columns is-gapless" style={{alignItems: "flex-end"}}>
                <div className="colum is-1">
                  <h1 className="redline-headline">
                    {ourservices.heading}
                  </h1>
                </div>
                <div className="colum" style={{width: "100%"}}>
                  <Link to={ourservices.link.url} className="btn-red our-services__button">{ourservices.link.text}</Link>
                </div>
              </div>
              <div className="our-services__content columns" style={{marginTop: "1rem"}}>
                {ourservices.callstoaction.map((item) => (
                  <a key={item.alt} href={item.url} className={`our-services__cta column ${column_count === 2 ? 'is-6' : 'is-4'}`}>
                    <PreviewCompatibleImage imageInfo={item.image} />
                    <h3>{item.heading}</h3>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>
      
      </div>
    </React.Fragment>
  );
}

OurServices.defaultProps = {
  column_count: 3,
}
// // What does this do?
// OurServices.propTypes = {
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   heading: PropTypes.string,
//   link: PropTypes.arrayOf(
//     PropTypes.shape({
//       url: PropTypes.string,
//       text: PropTypes.string,
//     })
//   ),
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       image: PropTypes.array,
//     })
//   ),
// };
