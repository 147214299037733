import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import GenericCopy from "../components/interior-components/GenericCopy";
import OurServices from "../components/OurServices";

export const ServiceAreasPageTemplate = ({
    title,
    heroImage,
    showTitle,
    thirdLevel,
    heroHeadline,
    romance_copy,
    cta_item_list,
    contentComponent,
    content
}) => {
    const HomePageContent = contentComponent || Content;
    return (
        <div className="main-page-content">
            <InteriorHero image={heroImage} title={title} heroHeadline={heroHeadline} showTitle={showTitle} thirdLevel={thirdLevel} />
            <GenericCopy copy={romance_copy.copy} />
            <OurServices ourservices={cta_item_list} column_count={2} />
            <section className="section main_copy">
                <div className="container">
                    <div className="section pt-0 pb-0">
                        <div className="columns is-gapless">
                            <div className="column is-full pl-5 pr-5 pt-3">
                                <HomePageContent className="content main-content" content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

ServiceAreasPageTemplate.propTypes = {
    title: PropTypes.string,
    heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    showTitle: PropTypes.bool,
    thirdLevel: PropTypes.bool,
    romance_copy: PropTypes.object,
    cta_item_list: PropTypes.object,
}

const ServiceAreasPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <ServiceAreasPageTemplate
                title={post.frontmatter.title}
                heroImage={post.frontmatter.heroImage}
                heroHeadline={post.frontmatter.heroHeadline}
                showTitle={post.frontmatter.showTitle}
                thirdLevel={post.frontmatter.thirdLevel}
                romance_copy={post.frontmatter.romance_copy}
                cta_item_list={post.frontmatter.cta_item_list}
                contentComponent={HTMLContent}
                content={post.html}
            />
        </Layout>
    );
}

ServiceAreasPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    })
}

export default ServiceAreasPage;

export const pageQuery = graphql`
query ServiceAreasPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "service-areas-page" } }) {
    html
    frontmatter {
        title
        heroHeadline
        showTitle
        thirdLevel
        heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        romance_copy{
          copy
        }
        cta_item_list{
            heading
            bgimage {
                childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
            }
            link{
                text
                url
            }
            callstoaction{
                image {
                    childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                }
                alt
                heading
                url
            }
        }
    }
  }
}
`;
